import React from 'react'
import { Trans } from 'react-i18next'

import { StepsContext } from 'apps/Imports/contexts/steps'
import { useDataProcessingPolicy } from 'apps/Imports/hooks/useDataProcessingPolicy'
import { useImportContext } from 'apps/Imports/hooks/useImportContext'
import { useMarketingPolicy } from 'apps/Imports/hooks/useMarketingPolicy'
import { IMPORT_TYPES } from 'apps/Imports/utils/constants'
import { PROFILE_IMPORT_STEPS } from 'apps/Imports/utils/profile-import/constants'

export const ProfileImportStepsProvider = ({
  children,
  importType = IMPORT_TYPES.PROFILE
}) => {
  const context = useImportContext()
  const marketingPolicy = useMarketingPolicy()
  const dataProcessingPolicy = useDataProcessingPolicy()

  const [stepsCompleted, setStepsCompleted] = React.useState([])
  const [currentStep, setCurrentStep] = React.useState(
    PROFILE_IMPORT_STEPS.FILE_UPLOAD
  )

  const hasConsentStep =
    marketingPolicy?.published || dataProcessingPolicy?.published

  const hasAddToStep =
    context?._backbone_type === 'Institutions.Models.ClassOf' ||
    context?._backbone_type === 'Events.Models.Event'

  const importSteps = [
    {
      id: PROFILE_IMPORT_STEPS.FILE_UPLOAD,
      label: <Trans>File upload</Trans>,
      active: currentStep === PROFILE_IMPORT_STEPS.FILE_UPLOAD,
      completed:
        stepsCompleted.includes(PROFILE_IMPORT_STEPS.FILE_UPLOAD) &&
        currentStep !== PROFILE_IMPORT_STEPS.FILE_UPLOAD
    },
    {
      id: PROFILE_IMPORT_STEPS.IMPORT_SETTINGS,
      label: <Trans>Import settings</Trans>,
      active: currentStep === PROFILE_IMPORT_STEPS.IMPORT_SETTINGS,
      completed:
        stepsCompleted.includes(PROFILE_IMPORT_STEPS.IMPORT_SETTINGS) &&
        currentStep !== PROFILE_IMPORT_STEPS.IMPORT_SETTINGS
    },
    hasConsentStep
      ? {
          id: PROFILE_IMPORT_STEPS.POLICY_CONSENT,
          label: <Trans>Consent</Trans>,
          active: currentStep === PROFILE_IMPORT_STEPS.POLICY_CONSENT,
          completed:
            stepsCompleted.includes(PROFILE_IMPORT_STEPS.POLICY_CONSENT) &&
            currentStep !== PROFILE_IMPORT_STEPS.POLICY_CONSENT
        }
      : null,
    hasAddToStep
      ? {
          id: PROFILE_IMPORT_STEPS.ADD_TO_CONTEXT,
          label: <Trans>Add to</Trans>,
          active: currentStep === PROFILE_IMPORT_STEPS.ADD_TO_CONTEXT,
          completed:
            stepsCompleted.includes(PROFILE_IMPORT_STEPS.ADD_TO_CONTEXT) &&
            currentStep !== PROFILE_IMPORT_STEPS.ADD_TO_CONTEXT
        }
      : null,
    {
      id: PROFILE_IMPORT_STEPS.PROFILE_DETAILS,
      label: <Trans>Profile Details</Trans>,
      active: currentStep === PROFILE_IMPORT_STEPS.PROFILE_DETAILS,
      completed:
        stepsCompleted.includes(PROFILE_IMPORT_STEPS.PROFILE_DETAILS) &&
        currentStep !== PROFILE_IMPORT_STEPS.PROFILE_DETAILS
    },
    {
      id: PROFILE_IMPORT_STEPS.MAPPING,
      label: <Trans>Mapping</Trans>,
      active: currentStep === PROFILE_IMPORT_STEPS.MAPPING,
      completed:
        stepsCompleted.includes(PROFILE_IMPORT_STEPS.MAPPING) &&
        currentStep !== PROFILE_IMPORT_STEPS.MAPPING
    },
    {
      id: PROFILE_IMPORT_STEPS.SUMMARY,
      label: <Trans>Summary</Trans>,
      active: currentStep === PROFILE_IMPORT_STEPS.SUMMARY,
      completed:
        stepsCompleted.includes(PROFILE_IMPORT_STEPS.SUMMARY) &&
        currentStep !== PROFILE_IMPORT_STEPS.SUMMARY
    },
    {
      id: PROFILE_IMPORT_STEPS.REPORT,
      label: <Trans>Report</Trans>,
      active: currentStep === PROFILE_IMPORT_STEPS.REPORT,
      completed: false
    }
  ].filter((x) => x)

  const currentStepIndex = importSteps.findIndex(
    (step) => step.id === currentStep
  )
  const previousStep =
    currentStepIndex > 0 ? importSteps[currentStepIndex - 1]?.id : null

  const nextStep =
    currentStepIndex !== importSteps.length - 1
      ? importSteps[currentStepIndex + 1]?.id
      : null

  return (
    <StepsContext.Provider
      value={{
        stepsCompleted,
        setStepsCompleted,
        currentStep,
        previousStep,
        nextStep,
        setCurrentStep,
        importSteps,
        importType
      }}
    >
      {children}
    </StepsContext.Provider>
  )
}

export default ProfileImportStepsProvider
