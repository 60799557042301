import React from 'react'
import { Trans } from 'react-i18next'

import { Field } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import ImportActions from 'apps/Imports/components/profile-import/import-actions'
import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import { useImportContext } from 'apps/Imports/hooks/useImportContext'
import { useLifecycle } from 'apps/Imports/hooks/useLifecycle'
import { useStaffs } from 'apps/Imports/hooks/useStaffs'
import { getStateOptions, staffToOptions } from './utils'

import styles from './styles.module.scss'

const AddToClassContextStep = () => {
  const context = useImportContext()
  const lifecycle = useLifecycle()
  const user = useCurrentUser()
  const staff = useStaffs()
  const {
    data: { addToData },
    onDataChanged
  } = useSelectedData()
  const onAddToDataChanged = React.useCallback(
    (field, value) => {
      onDataChanged('addToData', { ...addToData, [field]: value })
    },
    [onDataChanged, addToData]
  )

  return (
    <>
      <Field
        id='contextId'
        type='dropdown'
        value={context.id}
        onChange={onAddToDataChanged}
        inputOptions={{
          disabled: true,
          options: [{ value: context.id, label: context.name_with_programme }],
          className: styles.contextDropdown
        }}
        label={<Trans>Programme & Class</Trans>}
      />

      <Field
        id='stateSubstateId'
        type='dropdown'
        value={addToData.stateSubstateId}
        onChange={onAddToDataChanged}
        inputOptions={{
          options: getStateOptions(lifecycle, user),
          className: styles.statesDropdown
        }}
        required
        label={<Trans>State and substate</Trans>}
      />

      <Field
        id='ownerId'
        type='autocomplete'
        value={addToData.ownerId}
        onChange={onAddToDataChanged}
        inputOptions={{
          options: staff.map(staffToOptions),
          className: styles.ownerDropdown
        }}
        label={<Trans>Owner</Trans>}
      />

      <ImportActions />
    </>
  )
}

export default AddToClassContextStep
