export const IMPORTS_LIST_PAGE_ROUTE = '/admin/imports'
export const IMPORT_LOADING_PAGE_ROUTE = '/import/:documentId/loading'
export const IMPORT_REPORT_PAGE_ROUTE = '/import/:documentId'
export const EVENT_IMPORT_BASE_ROUTE = '/events/:eventId/import'
export const CLASS_IMPORT_BASE_ROUTE = '/classes/:classId/import'
export const ALL_PROFILES_IMPORT_BASE_ROUTE = '/profiles/import'
export const PROGRAMME_AND_CLASS_IMPORT_BASE_ROUTE =
  '/institutions/settings/programmes/import'
export const SUBJECTS_AND_COURSE_IMPORT_BASE_ROUTE =
  '/institutions/settings/courses/import'
