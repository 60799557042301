import React from 'react'
import { Route, Switch } from 'react-router'

import ImportLoadingPage from 'apps/Imports/pages/ImportLoadingPage'
import ImportReportPage from 'apps/Imports/pages/ImportReportPage'
import ImportsListPage from 'apps/Imports/pages/ImportsListPage'
import AllProfilesImportRoutes from './components/all-profiles-import-routes'
import ClassImportRoutes from './components/class-import-routes'
import EventImportRoutes from './components/event-import-routes'
import ProgrammeAndClassImportRoutes from './components/programme-and-class-import-routes'
import SubjectsAndCourseImportRoutes from './components/subject-and-course-import-routes'
import {
  ALL_PROFILES_IMPORT_BASE_ROUTE,
  CLASS_IMPORT_BASE_ROUTE,
  EVENT_IMPORT_BASE_ROUTE,
  IMPORT_LOADING_PAGE_ROUTE,
  IMPORT_REPORT_PAGE_ROUTE,
  IMPORTS_LIST_PAGE_ROUTE,
  PROGRAMME_AND_CLASS_IMPORT_BASE_ROUTE,
  SUBJECTS_AND_COURSE_IMPORT_BASE_ROUTE
} from './constants'

const ImportAppRoutes = () => {
  return (
    <Switch>
      <Route exact path={IMPORTS_LIST_PAGE_ROUTE} component={ImportsListPage} />
      <Route path={IMPORT_LOADING_PAGE_ROUTE} component={ImportLoadingPage} />
      <Route path={IMPORT_REPORT_PAGE_ROUTE} component={ImportReportPage} />

      <Route path={EVENT_IMPORT_BASE_ROUTE} component={EventImportRoutes} />
      <Route path={CLASS_IMPORT_BASE_ROUTE} component={ClassImportRoutes} />
      <Route
        path={ALL_PROFILES_IMPORT_BASE_ROUTE}
        component={AllProfilesImportRoutes}
      />
      <Route
        path={PROGRAMME_AND_CLASS_IMPORT_BASE_ROUTE}
        component={ProgrammeAndClassImportRoutes}
      />
      <Route
        path={SUBJECTS_AND_COURSE_IMPORT_BASE_ROUTE}
        component={SubjectsAndCourseImportRoutes}
      />
    </Switch>
  )
}

export default ImportAppRoutes
